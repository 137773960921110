exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-python-js": () => import("./../../../src/pages/python.js" /* webpackChunkName: "component---src-pages-python-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js-content-file-path-blog-ada-lovelace-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/ada-lovelace/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-ada-lovelace-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-apocalipse-da-programacao-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/apocalipse-da-programacao/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-apocalipse-da-programacao-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-bemvindo-tensorflow-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/bemvindo-tensorflow/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-bemvindo-tensorflow-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-black-hole-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/black-hole/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-black-hole-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-chatgpt-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/chatgpt/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-chatgpt-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-classification-basic-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/classification-basic/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-classification-basic-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-desafio-codigo-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/desafio-codigo/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-desafio-codigo-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-engenheiro-computacao-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/engenheiro-computacao/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-engenheiro-computacao-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-ideias-algoritmos-estrutura-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/ideias-algoritmos-estrutura/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-ideias-algoritmos-estrutura-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-instalacao-mac-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/instalacao-mac/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-instalacao-mac-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-instalacao-raspbian-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/instalacao-raspbian/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-instalacao-raspbian-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-instalacao-tensorflow-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/instalacao-tensorflow/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-instalacao-tensorflow-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-instalacao-ubuntu-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/instalacao-ubuntu/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-instalacao-ubuntu-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-instalacao-windows-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/instalacao-windows/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-instalacao-windows-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-inteligencia-artificial-google-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/inteligencia-artificial-google/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-inteligencia-artificial-google-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-programar-era-dificil-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/programar-era-dificil/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-programar-era-dificil-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-python-seguranca-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/python-seguranca/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-python-seguranca-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-quick-sort-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/quick-sort/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-quick-sort-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-regression-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/regression/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-regression-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-software-customizado-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/software-customizado/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-software-customizado-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-tabela-hash-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/tabela-hash/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-tabela-hash-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-tecnicas-produtividade-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/tecnicas-produtividade/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-tecnicas-produtividade-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-tecnologia-2021-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/tecnologia-2021/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-tecnologia-2021-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-blog-text-classification-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/blog/text-classification/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-text-classification-index-mdx" */)
}

